import { FC, useEffect, useState } from 'react'

import Filter from 'components/Filter'
import WithdrawTable from 'components/WithdrawTable'
import FilterModal from 'components/Filter/FilterModal'
import WithdrawCards from 'components/WithdrawCards'

import { PaymentSystem, WithdrawStatus } from 'assets/const'
import { Withdraw, WithdrawFilterParams, WithdrawPagingParams } from 'types/operation'
import { filterWithdrawSchema } from './validation'
import { useMediaSize } from 'hooks/useMediaSize'


const initialFilterParams: WithdrawFilterParams = {
  dateFrom: undefined,
  dateTo: undefined,
  status: undefined,
  paymentSystem: undefined,
}

const initialPagingParams: WithdrawPagingParams = {
  pageIndex: 0,
  pageSize: 5,
  sortField: 'updated',  //disable sorting
  sortDirection: 'desc',
}

const psToSelect = Object.entries(PaymentSystem).map(([value, label]) => ({
  label,
  value,
}))

const statusToSelect = Object.entries(WithdrawStatus).map(([value, label]) => {
  return {
    label,
    value,
  }
})

interface Props {
  setFilterModal: (props: boolean) => void
  setCountOfFilters: (num: number) => void
  filterModal: boolean
}

const TabWithdraws: FC<Props> = ({filterModal, setFilterModal, setCountOfFilters}) => {
  const {isPhone} = useMediaSize()

  const [filterParams, setFilterParams] = useState(initialFilterParams)
  const [pagingParams, setPagingParams] = useState(initialPagingParams)
  const [withdraw, setWithdraw] = useState<Withdraw | null>(null)

  const handleReset = () => {
    setFilterParams(initialFilterParams)
  }

  const handleSetFilterParams = (params: WithdrawFilterParams) => {
    setFilterParams((p) => ({ ...p, ...params }))
  }

  const handleSetPagingParams = (params: Partial<WithdrawPagingParams>) => {
    setPagingParams((p) => ({ ...p, ...params }))
  }

  useEffect(() => {
    setCountOfFilters(0)
  }, [])

  if (isPhone) return (
    <>
      <WithdrawCards
        filterParams={filterParams}
        pagingParams={pagingParams}
        setPagingParams={handleSetPagingParams}
        withdraw={withdraw}
        setWithdraw={setWithdraw}
      />
      {filterModal && (
        <FilterModal<WithdrawFilterParams>
          params={filterParams}
          selectOptions={psToSelect}
          schema={filterWithdrawSchema}
          clearParams={handleReset}
          selectLabel='Payment System'
          setParams={handleSetFilterParams}
          selectName='paymentSystem'
          statusOptions={statusToSelect}
          key={'WithdrawFilterParams'}
          onClose={() => setFilterModal(false)}
          setCountOfFilters={setCountOfFilters}
        />
      )}
    </>
  )

  return (
    <>
      <Filter<WithdrawFilterParams>
        params={filterParams}
        selectOptions={psToSelect}
        schema={filterWithdrawSchema}
        clearParams={handleReset}
        selectLabel='Payment System'
        setParams={handleSetFilterParams}
        selectName='paymentSystem'
        statusOptions={statusToSelect}
        key={'WithdrawFilterParams'}
      />
      <WithdrawTable
        filterParams={filterParams}
        pagingParams={pagingParams}
        setPagingParams={handleSetPagingParams}
        withdraw={withdraw}
        setWithdraw={setWithdraw}
      />
    </>
  )
}

export default TabWithdraws
