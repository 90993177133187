import { Dispatch, SetStateAction } from 'react'
import { TableCell, Typography } from '@mui/material'
import TableRow from '@mui/material/TableRow'

import StatusLabel from '../../StatusLabel'

import { WithdrawStatus } from 'assets/const'
import { useCurrencyByCode } from 'hooks/useCurrencyByCode'
import { Withdraw } from 'types/operation'
import { formatAmountForTable, formatBalanceWithDecimals, formatDate, formatTime } from 'utils/intlFormat'
import { getOperationValue } from 'utils/operation'
import s from '../../OperationTable/styles'


interface TableBodyRowProps {
  item: Withdraw
  pt?: number
  setWithdraw: Dispatch<SetStateAction<null | Withdraw>>
}

const TableBodyRow = ({ item, pt, setWithdraw }: TableBodyRowProps) => {
  const { currency } = useCurrencyByCode(item.currency)

  const date = formatDate(item.updated)
  const time = formatTime(item.updated)
  const commission = formatBalanceWithDecimals(item.commission)
  const statusLabel = getOperationValue(item.status, WithdrawStatus)

  return (
    <TableRow
      onClick={() => setWithdraw(item)}
      sx={{...s.row, ...s.bodyRow, ...s.withdrawRowGrid, ...s.operationRowHover, pt: `${pt}px`}}
    >
      <TableCell
        sx={{
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Typography>{date}</Typography>
        <Typography fontSize={12} color='gray.main'>{time}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{item.psCode}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{formatAmountForTable(item.amount, currency?.symbol)}</Typography>
      </TableCell>
      <TableCell>
        {!!item?.commission && (
          <Typography>{commission}</Typography>
        )}
      </TableCell>
      <TableCell>
        <StatusLabel
          status={item.status}
          label={statusLabel}
          sx={{ fontSize: 12 }}
        />
      </TableCell>
      <TableCell>
        <Typography
          sx={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            wordBreak: 'break-word',
            whiteSpace: 'normal',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
          }}
        >
          {item.comment}
        </Typography>
      </TableCell>
    </TableRow>
  )
}

export default TableBodyRow
