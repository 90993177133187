import { baseApiUrl } from 'assets/const'
import { EventSourcePolyfill } from 'event-source-polyfill'
import { ReactNode, useEffect, useRef } from 'react'
import { toast } from 'react-toastify'
import AuthService from 'services/AuthService'
import {
  updateSavedTemplateFailedAction,
  updateSavedTemplateSuccessAction,
  withdrawFailedAction,
  withdrawSuccessAction,
} from 'store/actions'
import { EventType } from 'types/sse-type'
import { uuid } from 'utils/uuid'
import { store } from '../store'

const SSE_TIMEOUT = 600000
const TOKEN_VALIDITY = 1500

type Props = {
  children: ReactNode
}

const SseProvider = ({ children }: Props) => {
  const eventSource = useRef<EventSourcePolyfill | null>(null)

  useEffect(() => {
    if (eventSource.current) {
      return
    }
    const connect = async () => {
      const token = await AuthService.updateTokenSse(TOKEN_VALIDITY)
      eventSource.current = new EventSourcePolyfill(
        `${baseApiUrl}sse?sessionId=${uuid}`,
        {
          headers: { Authorization: `Bearer ${token}` },
          heartbeatTimeout: SSE_TIMEOUT,
          withCredentials: false,
        }
      )

      eventSource.current.onerror = (e) => {
        console.log('error', e)
        if (eventSource?.current?.readyState === EventSource.CLOSED) {
          setTimeout(() => {
            connect()
          }, 0)
        }
      }

      eventSource.current.onmessage = (e) => {
        console.log('sse message:', e)
        const data = JSON.parse(e.data)
        const error = data.payload?.error
        if (error) {
          toast.error(data.payload?.message || error)
        }

        switch (data.type) {
          //different types of payload are coming and therefore I cannot partially update the templates. I have to do a refetch of all
          case EventType.CreateUserWithdrawTemplate:
          case EventType.DeleteUserWithdrawTemplate:
          case EventType.UpdateUserWithdrawTemplate:
          case EventType.WithdrawTemplate:
            error
              ? store.dispatch(updateSavedTemplateFailedAction())
              : store.dispatch(updateSavedTemplateSuccessAction())
            break
          case EventType.Withdraw:
          case EventType.WithdrawRequest:
          case EventType.CreateWithdrawRequest:
            error
              ? store.dispatch(withdrawFailedAction())
              : store.dispatch(withdrawSuccessAction())
            break
          default:
            console.log(data.type)
        }
      }
    }
    connect()
  }, [])

  return <>{children}</>
}

export default SseProvider