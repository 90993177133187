import compareAsc from 'date-fns/compareAsc'
import compareDesc from 'date-fns/compareDesc'
import * as yup from 'yup'

const optionalStringSchema = yup.string().nullable().notRequired();

const positiveIntegerValidation = optionalStringSchema.test(
  'isPositiveInteger',
  'Please enter a valid number',
  (value) => {
    const numberValue = Number(value);
    return !value
      ? true
      : Number.isInteger(numberValue) && numberValue > 0;
  });

export const filterOperationsSchema = yup.object().shape({
  dateFrom: optionalStringSchema.test(
    'dateBefore',
    'Please enter a valid date',
    (value, testContext) => {
      const dateTo = testContext.parent.dateTo
      return !!value && !!dateTo
        ? compareAsc(new Date(value), new Date(dateTo)) <= 0
        : true
    }
  ),
  dateTo: optionalStringSchema.test(
    'dateBefore',
    'Please enter a valid date',
    (value, testContext) => {
      const dateFrom = testContext.parent.dateFrom
      return !!value && !!dateFrom
        ? compareDesc(new Date(value), new Date(dateFrom)) <= 0
        : true
    }
  ),
  type: optionalStringSchema,
  status: optionalStringSchema,
  maxAmount: positiveIntegerValidation.test(
    'maxGreaterThanMin',
    'Must be greater than min amount',
    (value, testContext) => {
      const minAmount = Number(testContext.parent.minAmount)
      const maxAmount = Number(value)
      return !value || !minAmount || maxAmount >= minAmount
    }
  ),
  minAmount: positiveIntegerValidation.test(
    'minLessThanMax',
    'Must be less than max amount',
    (value, testContext) => {
      const maxAmount = Number(testContext.parent.maxAmount);
      const minAmount = Number(value);
      return !value || !maxAmount || minAmount <= maxAmount;
    }
  ),
})
