import { ReactComponent as CurrencyBTCIcon } from 'assets/svg/currency/BTC.svg'
import { ReactComponent as CurrencyCpyIcon } from 'assets/svg/currency/Cpy.svg'
import { ReactComponent as CurrencyETHIcon } from 'assets/svg/currency/Eth.svg'
import { ReactComponent as CurrencyEurIcon } from 'assets/svg/currency/Eur.svg'
import { ReactComponent as CurrencyJPYIcon } from 'assets/svg/currency/Jpy.svg'
import { ReactComponent as CurrencyNearIcon } from 'assets/svg/currency/Near.svg'
import { ReactComponent as CurrencyRubIcon } from 'assets/svg/currency/Rub.svg'
import { ReactComponent as CurrencyUSDIcon } from 'assets/svg/currency/USD.svg'
import { AuthMethod } from 'types/user'
import { WalletsPaymentSystem } from 'types/wallet'

import { validateUrl } from 'utils/validate-url'
import bitcoin from 'assets/svg/wallets/BTC.svg'
import ethereum from 'assets/svg/wallets/ETH.svg'
import stripe from 'assets/svg/wallets/Stripe.svg'
import mango from 'assets/svg/wallets/Mango.svg'
import cw from 'assets/svg/wallets/Cw.svg'

export const baseApiUrl = validateUrl(process.env.REACT_APP_API_URL)
export const portalUiUrl = validateUrl(process.env.REACT_APP_PORTAL_URL)
export const portalApiUrl = validateUrl(process.env.REACT_APP_PORTAL_API_URL)
export const gravatarUrl =
  validateUrl(process.env.REACT_APP_GRAVATAR_BASE_URI) ||
  'https://www.gravatar.com/avatar/'

export const AUTH_CLIENT_ID = 'cw-wallet-ui'

export const getCurrencyIcon = {
  USD: CurrencyUSDIcon,

  RUB: CurrencyRubIcon,

  EUR: CurrencyEurIcon,

  CPY: CurrencyCpyIcon,

  AUD: CurrencyUSDIcon,

  JPY: CurrencyJPYIcon,

  Near: CurrencyNearIcon,

  BTC: CurrencyBTCIcon,

  ETH: CurrencyETHIcon,
}

export const getPaymentIconByPaymentSystem: Record<WalletsPaymentSystem, string> = {
  'stripe': stripe,
  'mango': mango,
  'cw': cw,
  'eth': ethereum,
  'btc': bitcoin,
}

export const OperationStatus = {
  none: 'Any',
  failed: 'Failed',
  canceled: 'Cancelled',
  succeeded: 'Succeeded',
  pending: 'Pending',
  blocked: 'Blocked',
  unblocked: 'Unblocked',
} as const

export const OperationType = {
  none: 'Any',
  refill: 'Refill',
  withdraw: 'Withdraw',
  payment: 'Payment',
  transfer: 'Transfer',
  exchange: 'Exchange',
  free: 'Free',
  refund: 'Refund',
  invoice: 'Invoice',
  cashin: 'Cash In',
  cashout: 'Cash Out',
} as const

export const WithdrawStatus = {
  none: 'Any',
  created: 'Created',
  inProgress: 'In Progress',
  confirmed: 'Confirmed',
  declined: 'Declined',
  cancelled: 'Cancelled',
  processedManually: 'Processed Manually',
} as const

export enum PaymentSystem {
  STRIPE = 'STRIPE',
  ETH = 'ETH',
  BTC = 'BTC',
  MANGO = 'MANGO',
}

export const getPaymentSetting = {
  [PaymentSystem.STRIPE]: 'stripe',
  [PaymentSystem.ETH]: 'etc',
  [PaymentSystem.BTC]: 'btc',
}

export const getPaymentIcon = {
  [PaymentSystem.STRIPE]: stripe,
  [PaymentSystem.ETH]: ethereum,
  [PaymentSystem.BTC]: bitcoin,
  [PaymentSystem.MANGO]: mango,
}

export const getPaymentIconByCode = {
  '4': mango,
  '3': stripe,
  '2': ethereum,
  '1': bitcoin,
}

export enum OperationField {
  type,
  status,
  amount,
  description,
  created,
  balance,
}

export enum WithdrawField {
  status,
  amount,
  comment,
  updated,
  method,
  commission,
}

export const AppRoute = {
  Root: '/',
  AddWallet: 'add-wallet',
  Withdraw: 'withdraw',
  Profile: 'profile',
  Home: '/',
  Operation: 'operation-info',
  Faq: 'faq',
  More: 'more',
  Terms: 'more/terms',
  Payments: 'payments',
  Payment: 'payment',
  Templates: 'templates',
  AuthSettings: 'authentication-settings',
  IpWhitelist: 'ip-whitelist',
  Transfer: 'transfer',
  Refill: 'refill',
  StripeProfile: 'stripe',
  NotFound: 'not-found',
  Settings: 'settings'
} as const

export const AuthType = {
  email: 'email',
  sms: 'sms',
  totp: 'totp',
  none: 'none',
} as const

export const AuthLabel = {
  email: 'Email',
  sms: 'SMS',
  totp: 'Google Authenticator',
  none: 'Disabled',
} as const

export const ResponseError = {
  DefaultWallet: {
    code: 33,
    message: `You can't unset this default wallet`,
  },
}

export const authenticationSettings: {
  label: string
  value: AuthMethod
}[] = [
  { label: AuthLabel.totp, value: AuthType.totp },
  //   { label: AuthLabel.sms, value: AuthType.Sms },
  //   { label: AuthLabel.email, value: AuthType.Email },
]

export const authenticationDisableOption = {
  label: AuthLabel.none,
  value: AuthType.none,
} as const

export const IpValue = {
  IpV4Single: 'ipV4Single',
  IpV4Range: 'ipV4Range',
  IpV6Single: 'ipV6Single',
} as const

export const GetIpFieldsQty = {
  [IpValue.IpV4Range]: 5,
  [IpValue.IpV4Single]: 4,
  [IpValue.IpV6Single]: 1,
}

export const ipOptions = [
  { label: 'Single IPv4 Address', value: IpValue.IpV4Single },
  { label: 'IPv4 Address Range', value: IpValue.IpV4Range },
  { label: 'Single IPv6 Address', value: IpValue.IpV6Single },
]

export const OperationTabLabel = {
  AccountTransfer: 'Account Transfer',
  AccountPayment: 'Account',
  WithdrawDetails: 'Details',
  Security: 'Security',
  Done: 'Done',
} as const

export const DrawerVariant = {
  Dashboard: 'Dashboard',
  Profile: 'Profile',
} as const
