import { FC, ReactNode } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import { DialogProps } from '@mui/material/Dialog/Dialog'
import { Typography } from '@mui/material'

import s from './styles'


interface Props {
  modalWidth?: number
  children: ReactNode
  handleClose: () => void
  title?: string
}

const Modal: FC<Props & DialogProps> = ({
  children,
  modalWidth,
  handleClose,
  open = true,
  title,
  ...rest
}) => (
  <Dialog
    {...rest}
    open={open}
    onClose={(_, reason) => {
      if (reason !== 'backdropClick') {
        handleClose();
      }
    }}
    maxWidth={false}
    scroll={'body'}
    PaperProps={{
      sx: { borderRadius: '8px', width: modalWidth },
    }}
  >
    <Box sx={s.modal}>
      <CloseIcon onClick={handleClose} sx={s.closeButton}/>
      <Typography sx={s.title(title ? 3 : 0)}>{title}</Typography>
      {children}
    </Box>
  </Dialog>
)

export default Modal
