import { Dispatch, SetStateAction } from 'react'
import { TableCell, Typography } from '@mui/material'
import TableRow from '@mui/material/TableRow'

import StatusLabel from '../../StatusLabel'

import { OperationStatus, OperationType } from 'assets/const'

import { formatAmountForTable, formatDate, formatTime } from 'utils/intlFormat'
import { getOperationValue, removeUnderscore} from 'utils/operation'

import { Currency } from 'types/currency'
import { Operation } from 'types/operation'

import s from '../styles'


interface TableBodyRowProps {
  item: Operation
  pt?: number
  currency: Currency | undefined
  setOperationId: Dispatch<SetStateAction<string>>
}

const TableBodyRow = ({ item, pt, currency, setOperationId }: TableBodyRowProps) => {
  const type = removeUnderscore(getOperationValue(item.type, OperationType))
  const date = formatDate(item.created)
  const time = formatTime(item.created)
  const statusLabel = getOperationValue(item.status, OperationStatus)

  return (
    <TableRow
      onClick={() => setOperationId(item.id)}
      sx={{ ...s.row, ...s.bodyRow, ...s.operationRowGrid, ...s.operationRowHover, pt: `${pt}px` }}
    >
      <TableCell
        sx={{
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Typography>{date}</Typography>
        <Typography fontSize={12} color='gray.main'>{time}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{type}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{formatAmountForTable(item.amount, currency?.symbol)}</Typography>
      </TableCell>
      <TableCell>
        <StatusLabel
          status={item.status}
          sx={{ fontSize: 12 }}
          label={statusLabel}
        />
      </TableCell>
      <TableCell>
        <Typography
          sx={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            wordBreak: 'break-word',
            whiteSpace: 'normal',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
          }}
        >
          {item.description}
        </Typography>
      </TableCell>
    </TableRow>
  )
}

export default TableBodyRow
