import { FC } from 'react'
import Chip from '@mui/material/Chip'
import { FieldValues } from 'react-hook-form'
import { formatDate } from 'utils/intlFormat'

import s from '../styles'


const paramsKeysForChip: FieldValues = {
  dateFrom: 'From',
  dateTo: 'Till',
  message: 'Message',
  status: 'Status',
  maxAmount: 'Max amount',
  minAmount: 'Min amount',
  type: 'Type',
}

interface Props {
  name: string
  value: string
  handleDelete: () => void
}

const FilterChip: FC<Props> = ({name, value, handleDelete}) => (
  <Chip
    key={name}
    sx={s.filterChip}
    onClick={(e) => e.stopPropagation()}
    label={
      `${paramsKeysForChip[name]}:
      ${['dateFrom', 'dateTo'].includes(name)
        ? formatDate(value)
        : value === 'none' ? 'any' : value}`
    }
    onDelete={handleDelete}
  />
)

export default FilterChip
